import React from 'react';

const Cases = () => {
  return (
    <div>
      <h1>Cases</h1>
      <p></p>
    </div>
  );
};

export default Cases;
